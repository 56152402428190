import React from "react";

const Repeat = () => (
  <path
    d="M2.00112 9.95703V9.70703C2.15737 7.20703 4.25112 5.23828 6.75112 5.23828H13.4386L11.7199 3.51953C11.4074 3.23828 11.4074 2.76953 11.7199 2.45703C12.0011 2.17578 12.4699 2.17578 12.7824 2.45703L15.7824 5.45703C16.0636 5.76953 16.0636 6.23828 15.7824 6.51953L12.7824 9.51953C12.4699 9.83203 12.0011 9.83203 11.7199 9.51953C11.4074 9.23828 11.4074 8.76953 11.7199 8.45703L13.4386 6.73828H6.75112C5.03237 6.73828 3.59487 8.08203 3.50112 9.80078V10.0508C3.46987 10.457 3.09487 10.7695 2.68862 10.7383C2.28237 10.7383 1.96987 10.3633 2.00112 9.95703ZM17.9699 10.0508H18.0011L17.9699 10.3008C17.8136 12.8008 15.7511 14.7383 13.2199 14.7383H6.53237L8.25112 16.457C8.56362 16.7695 8.56362 17.2383 8.25112 17.5195C7.96987 17.832 7.50112 17.832 7.21987 17.5195L4.21987 14.5195C3.90737 14.2383 3.90737 13.7695 4.21987 13.457L7.21987 10.4883C7.50112 10.1758 7.96987 10.1758 8.25112 10.4883C8.56362 10.7695 8.56362 11.2383 8.25112 11.5195L6.53237 13.2383H13.2199C14.9386 13.2383 16.3761 11.9258 16.4699 10.207L16.5011 9.95703C16.5011 9.55078 16.8761 9.23828 17.2824 9.23828C17.6886 9.26953 18.0011 9.64453 17.9699 10.0508Z"
    fill="currentColor"
  />
);

export default Repeat;
