import React, { useCallback, useEffect, useRef, useState } from "react";
import { BrowserRouter } from "react-router-dom";

import BlockEditorContent from "../../shared/block-editor-data/react";
import { RichTextDocument } from "../../shared/block-editor-data/types";
import Alert from "../../shared/components/design-system/Alert";
import Button from "../../shared/components/design-system/Button";
import LoadingSpinner from "../../shared/components/design-system/LoadingSpinner";
import TabbedContent, { Tab } from "../../shared/components/design-system/tabs/TabbedContent";
import { useTranslate } from "../translation/frontend";
import { THEME } from "../utils/html-or-lexical";
import { SerialisedAccount } from "./api/account";
import EmptyStateBanner from "./components/EmptyStateBanner/EmptyStateBanner";
import LoginCode from "./components/LoginCode";
import MessagingPanel from "./components/MessagingPanel/MessagingPanel";
import ReportPanel from "./components/ReportPanel/ReportPanel";
import ReporterSendMessage from "./components/SendMessage/SendMessage";
import SettingsPanel from "./components/SettingsPanel/SettingsPanel";
import useAccount from "./hooks/useAccount";
import useMessages from "./hooks/useMessages";
import useReport from "./hooks/useReport";
import "./styles.scss";

export interface ReporterMessagingProps {
  showCopyWarning: boolean;
  sla: RichTextDocument | null | undefined;
}

export default function ReporterMessaging({ showCopyWarning = false, sla = null }: ReporterMessagingProps) {
  const translate = useTranslate();

  const { loggedIn, logIn, authCode, account, deleteAccount, showAccountDeleteWarning } = useAccount();

  // Track if the user has been logged in at any point in the current session — we could infer this as it's just loggedIn || showAccountDeleteWarning, but this is more semantic and will still work if we implement a log-out button (which we probably should).
  const [hasLoggedIn, setHasLoggedIn] = useState(false);
  useEffect(() => {
    if (loggedIn) setHasLoggedIn(true);
  }, [loggedIn]);

  if (loggedIn) {
    return (
      <section className="section support report-messaging-section">
        <LoggedInArea sla={sla} authCode={authCode!} account={account} deleteAccount={deleteAccount} />
      </section>
    );
  }

  return (
    <section className="section support list-section">
      <div className="messaging__container reportMessaging">
        <p className="messaging__body">{translate("messagingCodeLogInBody")}</p>
        <LoginCode sendLoginCode={logIn} />
        {showAccountDeleteWarning ? (
          <Alert variant="warning">
            <span>{translate("messagingCodeDisabled")}</span>
          </Alert>
        ) : null}
        {hasLoggedIn ? null : showCopyWarning ? (
          <Alert variant="danger">
            <span>{translate("messagingCodeWarning")}</span>
          </Alert>
        ) : (
          <p>{translate("messagingCodeHelp")}</p>
        )}
      </div>
    </section>
  );
}

function LoggedInArea({
  authCode,
  sla,
  account,
  deleteAccount,
}: {
  authCode: string;
  sla: RichTextDocument | null;
  account: SerialisedAccount | null;
  deleteAccount: (authCode: string) => Promise<void>;
}) {
  const translate = useTranslate();
  const { messages, post, loading, error } = useMessages(authCode);
  const { loadingReport, errorReport, report } = useReport(authCode);
  const titleRef = useRef<HTMLHeadingElement>(null);

  const [childLoaded, setChildLoaded] = useState<boolean>(false);

  useEffect(() => {
    if (childLoaded) {
      titleRef.current?.focus();
    }
  }, [childLoaded]);

  const logOut = useCallback(() => {
    window.location.reload();
  }, []);

  if (loading || loadingReport) {
    return (
      <div className="messaging__container reportMessaging messagingPanel">
        <LoadingSpinner />
      </div>
    );
  }

  if (error || errorReport) {
    // TODO: translate this:
    return (
      <div className="messaging__container reportMessaging messagingPanel">
        <EmptyStateBanner
          title="Unable to load messages"
          body="Please refresh the page and try again"
          variant="empty"
        />
      </div>
    );
  }

  return (
    <BrowserRouter>
      <div className="tab-content">
        <h2 ref={titleRef} className="is-sr-only" tabIndex={-1}>
          Messaging
        </h2>
        <div className="logout">
          <Button
            variant="tertiary"
            size="medium"
            icon="signOut"
            label={translate("messagingLogoutAccountButton")}
            onClick={logOut}
            className="logout-btn"
          />
        </div>
        <TabbedContent variant="default">
          <Tab label={translate("messagingMessagesTab")}>
            <div className="messaging__container reportMessaging messagingPanel">
              {sla ? (
                <Alert className="ds-mb-6">
                  <BlockEditorContent content={sla} theme={THEME} />
                </Alert>
              ) : null}
              {account?.messagingStatus === "DISABLED_BY_CASEWORKER" ? (
                <Alert variant="warning">
                  <span>{translate("messagingCodeDisabledByCaseworker")}</span>
                </Alert>
              ) : (
                <div>
                  <ReporterSendMessage post={post} setLoadState={setChildLoaded} />
                </div>
              )}

              {messages.length > 0 ? (
                <MessagingPanel messages={messages} />
              ) : (
                <EmptyStateBanner
                  title={translate("reporterMessagingEmptyTitle")}
                  body={translate("reporterMessagingEmptyBody")}
                  variant="empty"
                />
              )}
            </div>
          </Tab>
          <Tab label={translate("yourReport")}>
            <ReportPanel report={report!} />
          </Tab>
          <Tab label={translate("messagingSettingsTab")}>
            <SettingsPanel authCode={authCode} deleteAccount={deleteAccount} />
          </Tab>
        </TabbedContent>
      </div>
    </BrowserRouter>
  );
}
