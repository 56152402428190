import React, { FormEvent, FunctionComponent, useCallback, useState } from "react";

import Button from "../../../../../shared/components/design-system/Button";
import Dialog from "../../../../../shared/components/design-system/Dialog";
import { useToast } from "../../../../../shared/components/design-system/Toaster/context";
import {
  SectionFooter,
  SectionHeader,
} from "../../../../../shared/components/design-system/component-groups/section-header-footer";
import { useTranslate } from "../../../../translation/frontend";

const DisableAccountDialog: FunctionComponent<{
  modalOpen: boolean;
  closeModal: () => void;
  authCode: string;
  deleteAccount: (authCode: string) => Promise<void>;
}> = ({ modalOpen, closeModal, authCode, deleteAccount }) => {
  const translate = useTranslate();
  const toast = useToast();

  const [loading, setLoading] = useState(false);

  const handleDeleteAccount = useCallback(
    async (ev: FormEvent<HTMLFormElement>) => {
      ev.preventDefault();
      if (loading) return;
      setLoading(true);
      try {
        await deleteAccount(authCode);
      } catch (e) {
        // TODO: translate this
        toast.error("An error occurred while deleting your account");

        console.error(e);
      } finally {
        setLoading(false);
      }
    },
    [loading, deleteAccount, toast, authCode],
  );

  return (
    <>
      <Dialog isOpen={modalOpen} onClose={closeModal} closeOnClickOutside>
        <SectionHeader title={translate("messagingDisableAccountConfirm")} />
        {translate("messagingDisableAccountModal")}
        <form onSubmit={handleDeleteAccount}>
          <SectionFooter>
            <Button variant="tertiary" label={translate("cancel")} onClick={closeModal} />
            <Button variant="danger" label={translate("messagingDisableAccountButton")} submit loading={loading} />
          </SectionFooter>
        </form>
      </Dialog>
    </>
  );
};

export default DisableAccountDialog;
