import React, { PropsWithChildren, ReactNode, createContext, useCallback, useContext, useRef } from "react";
import { createPortal } from "react-dom";

// Wrapping the payload of this context in a function is a bit roundabout, but it means we can always get the current value from the ref without forcing re-renders, and it means we can refer to this file on the server, where `document` is undefined, as long as we never do anything to *invoke* this function (which should be fine)
const context = createContext(() => document.getElementById("modal-container") as HTMLDivElement);

/** Call this when you need to portal some content up the dom tree, but not so far up the dom tree as to be outside the current modal and therefore non-interactable */
export default function useInject(content: ReactNode) {
  let position = useContext(context)();

  // If we don't have an injection point because we're running tests, just create a throwaway div to mount things in.
  if (!position && process.env.NODE_ENV === "test") position = document.createElement("div");
  // Otherwise if we don't have one, something's gone wrong.
  if (!position) throw new Error("Attempted to inject content without an injection point");

  return createPortal(content, position);
}

/** Wrap the contents of modals in this, so that any new elements that get injected are injected within the modal, allowing our document tree structure to stay nice. You don't need one of these at the root — the default value works just fine. */
export function InjectablePoint({ children }: PropsWithChildren<unknown>) {
  const parent = useContext(context);
  const ref = useRef<HTMLDivElement | null>(null);
  const getInjectionPoint = useCallback(() => ref.current ?? parent(), [ref, parent]);
  return (
    <context.Provider value={getInjectionPoint}>
      {children}
      <div ref={ref} />
    </context.Provider>
  );
}
