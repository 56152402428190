import React from "react";

const AlignLeft = () => (
  <path
    d="M3.75 3.25C3.33437 3.25 3 3.58437 3 4C3 4.41562 3.33437 4.75 3.75 4.75H16.25C16.6656 4.75 17 4.41562 17 4C17 3.58437 16.6656 3.25 16.25 3.25L3.75 3.25ZM3.75 7.25C3.33437 7.25 3 7.58437 3 8C3 8.41563 3.33437 8.75 3.75 8.75H16.25C16.6656 8.75 17 8.41563 17 8C17 7.58437 16.6656 7.25 16.25 7.25H3.75ZM3 12C3 12.4156 3.33437 12.75 3.75 12.75H16.25C16.6656 12.75 17 12.4156 17 12C17 11.5844 16.6656 11.25 16.25 11.25H3.75C3.33437 11.25 3 11.5844 3 12ZM3.75 15.25C3.33437 15.25 3 15.5844 3 16C3 16.4156 3.33437 16.75 3.75 16.75H11.25C11.6656 16.75 12 16.4156 12 16C12 15.5844 11.6656 15.25 11.25 15.25H3.75Z"
    fill="currentColor"
  />
);

export default AlignLeft;
