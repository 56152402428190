import React from "react";

import BlockEditorContent from "./react";
import sanitise from "./sanitise-html";
import { Theme } from "./theme";
import { validateRichTextDocumentOrThrow } from "./validate";

export function validatedHtmlOrLexical(data: string) {
  if (data[0] === "{") return validateRichTextDocumentOrThrow(data);
  return sanitise(data);
}

/** Renders the HTML, if it is HTML and it passes validation, otherwise renders the string as Lexical (again if it passes validation) */
export function HtmlOrLexicalContent({ content, theme }: { content: string | null | undefined; theme: Theme }) {
  if (!content) return null;
  const validated = validatedHtmlOrLexical(content);
  if (typeof validated === "string") return <div dangerouslySetInnerHTML={{ __html: validated }} />;
  return <BlockEditorContent content={validated} theme={theme} />;
}
