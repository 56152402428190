import { useReducer, useRef } from "react";

/** Returns a value and a function. The value changes every time you call the function. The idea is you call the function when you want to rerender the component, and pass the value  */
export function useForceRerenderWithValue(): [React.Key, () => void] {
  return useReducer((a) => a + 1, 0);
}

/** Returns a function that you can call to trigger a re-render */
export function useForceRerender(): () => void {
  return useForceRerenderWithValue()[1];
}

/** Returns true during the first render and false otherwise. */
export function useFirstRender() {
  const firstRender = useRef(true);
  const wasFirstRender = firstRender.current;
  firstRender.current = false;
  return wasFirstRender;
}
