import React from "react";

const Emails = () => (
  <path
    d="M10.419 3.5C6.82526 3.5 3.91901 6.4375 3.91901 10C3.91901 13.5938 6.82526 16.5 10.419 16.5C10.8253 16.5 11.169 16.8438 11.169 17.25C11.169 17.6875 10.8253 18 10.419 18C5.98151 18 2.41901 14.4375 2.41901 10C2.41901 5.59375 5.98151 2 10.419 2C14.8253 2 18.419 5.59375 18.419 10V10.875C18.419 12.4688 17.1065 13.75 15.544 13.75C14.544 13.75 13.7003 13.2812 13.169 12.5312C12.4815 13.2812 11.5128 13.75 10.419 13.75C8.32526 13.75 6.66901 12.0938 6.66901 10C6.66901 7.9375 8.32526 6.25 10.419 6.25C11.294 6.25 12.1378 6.59375 12.7628 7.09375C12.919 6.90625 13.1378 6.75 13.419 6.75C13.8253 6.75 14.169 7.09375 14.169 7.5V10V10.875C14.169 11.6562 14.7628 12.25 15.544 12.25C16.294 12.25 16.919 11.6562 16.919 10.875V10C16.919 6.4375 13.9815 3.5 10.419 3.5ZM12.669 10C12.669 9.21875 12.2315 8.46875 11.544 8.0625C10.8253 7.65625 9.98151 7.65625 9.29401 8.0625C8.57526 8.46875 8.16901 9.21875 8.16901 10C8.16901 10.8125 8.57526 11.5625 9.29401 11.9688C9.98151 12.375 10.8253 12.375 11.544 11.9688C12.2315 11.5625 12.669 10.8125 12.669 10Z"
    fill="currentColor"
  />
);

export default Emails;
