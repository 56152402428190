import { VisuallyHidden } from "@react-aria/visually-hidden";
import React, { Ref, forwardRef, useCallback } from "react";
import { Link } from "react-router-dom";

import { Translatable, useTranslate } from "../../translation";
import Icon, { IconSlug } from "../Icon";
import LoadingSpinner from "../LoadingSpinner";
import baseStyles from "./styles/base.module.scss";
import variantStyles from "./styles/variants.module.scss";
import useLoadingDelay from "./useLoadingDelay";

export const variantClassNames = {
  primary: variantStyles.primary,
  secondary: variantStyles.secondary,
  tertiary: variantStyles.tertiary,
  danger: variantStyles.danger,
  action: variantStyles.action,
  placeholder: variantStyles.placeholder,
};
export type Variant = keyof typeof variantClassNames;

export const sizeClassNames = {
  xsmall: baseStyles.xsmall,
  small: baseStyles.small,
  medium: baseStyles.medium,
  large: baseStyles.large,
  fill: baseStyles.fill,
};
export enum Surface {
  WHITE,
  GREY,
}

export interface GlobalButtonProps {
  icon?: IconSlug;
  label: Translatable;
  onClick?: () => void;
  className?: string;
  variant?: Variant;
  disabled?: boolean;
  isActive?: boolean;
  testId?: string;
  surface?: Surface;
}

export type ButtonSizeProps =
  | {
      iconButton?: false;
      size?: "small" | "medium" | "large" | "fill";
      screenReaderLabel?: Translatable;
      iconSide?: "left" | "right";
    }
  | { iconButton: true; size?: "xsmall" | "small" | "medium" };

export interface ActionButtonProps {
  loading?: boolean;
  loadingText?: Translatable;
  disableLoadingDelay?: boolean;
  icon?: IconSlug;
}
export interface LinkButtonProps {
  to: string;
  linkType: "external" | "internal" | "download";
}

export type ButtonActionProps = LinkButtonProps | ({ to?: undefined; submit?: boolean } & ActionButtonProps);

export type ButtonProps = GlobalButtonProps & ButtonSizeProps & ButtonActionProps;

interface UnrestrictedButtonProps extends GlobalButtonProps {
  size?: keyof typeof sizeClassNames;
  iconButton?: boolean;
  iconSide?: "left" | "right";
  screenReaderLabel?: string;
  to?: string;
  linkType?: "external" | "internal" | "download";
  submit?: boolean;
  loading?: boolean;
  loadingText?: Translatable;
  disableLoadingDelay?: boolean;
  isActive?: boolean;
}

type ButtonClassProps = ButtonSizeProps &
  Pick<UnrestrictedButtonProps, "className" | "variant" | "disabled" | "isActive" | "loading" | "surface">;

export function useButtonProps({
  variant = "primary",
  className = "",
  size = "medium",
  iconButton,
  isActive,
  disabled,
  loading,
  surface,
}: ButtonClassProps) {
  return {
    className: `
      ${baseStyles.Button}
      ${variantClassNames[variant]}
      ${className}
      ${sizeClassNames[size]}
      ${isActive ? variantStyles.active : ""}
      ${disabled ? variantStyles.disabled : ""}
      ${loading ? variantStyles.loading : ""}
      ${iconButton ? baseStyles.Icon : ""}
      ${surface === Surface.GREY ? variantStyles.surfaceGrey : ""}
    `,
  };
}

const Button = forwardRef<HTMLElement, ButtonProps>((props, ref) => {
  const translate = useTranslate();

  const {
    disableLoadingDelay,
    loading,
    submit,
    label,
    loadingText,
    icon,
    iconSide,
    onClick,
    disabled,
    to,
    linkType,
    screenReaderLabel,
    isActive,
    testId,
    iconButton,
    surface = Surface.WHITE,
  } = props as UnrestrictedButtonProps;

  const isLoading = useLoadingDelay({ disable: disableLoadingDelay, loading });

  // The click handler is wrapped because Typescript will allow you to pass in a function with an optional first parameter, and we don't want to pass the click event into a function that's expecting something else.
  const internalUseClick = useCallback(() => onClick?.(), [onClick]);

  const buttonProps = {
    ...useButtonProps({ ...props, loading: isLoading, surface }),
    onClick: internalUseClick,
    "data-testid": testId,
    title: iconButton ? translate(label) : undefined,
  };

  const contentProps = {
    icon,
    label: translate(isLoading ? loadingText ?? label : label),
    loading: isLoading,
    hideLabel: !!iconButton,
    iconSide,
  };

  if (to && !disabled) {
    if (linkType === "internal") {
      return (
        <Link
          ref={ref as Ref<HTMLAnchorElement>}
          to={to}
          {...buttonProps}
          aria-label={translate(screenReaderLabel) ?? undefined}
          aria-current={isActive ? "page" : undefined}
        >
          <ButtonContents {...contentProps} />
        </Link>
      );
    }
    return (
      <a
        ref={ref as Ref<HTMLAnchorElement>}
        href={to}
        {...buttonProps}
        download={linkType === "download"}
        target="_blank"
        rel="noreferrer"
        aria-label={screenReaderLabel}
        aria-current={isActive ? "page" : undefined}
      >
        <ButtonContents {...contentProps} icon={icon} />
      </a>
    );
  }

  return (
    <button
      ref={ref as Ref<HTMLButtonElement>}
      type={submit ? "submit" : "button"}
      {...buttonProps}
      disabled={disabled || loading}
      aria-label={screenReaderLabel}
      aria-pressed={isActive}
    >
      <ButtonContents {...contentProps} />
    </button>
  );
});

export function ButtonContents({
  icon,
  label,
  loading,
  hideLabel,
  iconSide,
}: {
  icon?: IconSlug;
  label: string;
  loading?: boolean;
  hideLabel: boolean;
  iconSide?: "left" | "right";
}) {
  if (loading) {
    return (
      <>
        <div className={baseStyles.ButtonIcon}>
          <LoadingSpinner size="small" />
        </div>
        <ButtonLabel label={label} hideLabel={hideLabel} />
      </>
    );
  }

  if (iconSide === "right") {
    return (
      <>
        <ButtonLabel label={label} hideLabel={hideLabel} />
        {icon ? (
          <div className={baseStyles.ButtonIcon}>
            <Icon icon={icon} />
          </div>
        ) : null}
      </>
    );
  }

  return (
    <>
      {icon ? (
        <div className={baseStyles.ButtonIcon}>
          <Icon icon={icon} />
        </div>
      ) : null}
      <ButtonLabel label={label} hideLabel={hideLabel} />
    </>
  );
}

export function ButtonLabel({ label, hideLabel }: { label: string; hideLabel: boolean }) {
  if (hideLabel) return <VisuallyHidden>{label}</VisuallyHidden>;
  return <span className={baseStyles.ButtonLabel}>{label}</span>;
}

export default Button;
