import React from "react";

const ListCheck = () => (
  <path
    d="M6.78125 4.63651V4.60526L4.53125 7.10526C4.40625 7.26151 4.21875 7.35526 4 7.35526C3.8125 7.38651 3.59375 7.29276 3.46875 7.13651L2.21875 5.88651C1.90625 5.60526 1.90625 5.13651 2.21875 4.82401C2.5 4.54276 2.96875 4.54276 3.28125 4.82401L3.96875 5.54276L5.6875 3.60526C5.96875 3.32401 6.4375 3.29276 6.75 3.57401C7.03125 3.85526 7.0625 4.32401 6.78125 4.63651ZM6.78125 9.63651V9.60526L4.53125 12.1053C4.40625 12.2615 4.21875 12.3553 4 12.3553C3.8125 12.3865 3.59375 12.2928 3.46875 12.1365L2.21875 10.8865C1.90625 10.6053 1.90625 10.1365 2.21875 9.82401C2.5 9.54276 2.96875 9.54276 3.28125 9.82401L3.96875 10.5428L5.6875 8.60526C5.96875 8.32401 6.4375 8.29276 6.75 8.57401C7.03125 8.85526 7.0625 9.32401 6.78125 9.63651ZM8.75 6.10526C8.3125 6.10526 8 5.79276 8 5.35526C8 4.94901 8.3125 4.60526 8.75 4.60526H17.25C17.6562 4.60526 18 4.94901 18 5.35526C18 5.79276 17.6562 6.10526 17.25 6.10526H8.75ZM8 10.3553C8 9.94901 8.3125 9.60526 8.75 9.60526H17.25C17.6562 9.60526 18 9.94901 18 10.3553C18 10.7928 17.6562 11.1053 17.25 11.1053H8.75C8.3125 11.1053 8 10.7928 8 10.3553ZM7 15.3553C7 14.949 7.3125 14.6053 7.75 14.6053H17.25C17.6562 14.6053 18 14.949 18 15.3553C18 15.7928 17.6562 16.1053 17.25 16.1053H7.75C7.3125 16.1053 7 15.7928 7 15.3553ZM5 15.3553C5 15.9178 4.53125 16.3553 4 16.3553C3.4375 16.3553 3 15.9178 3 15.3553C3 14.824 3.4375 14.3553 4 14.3553C4.53125 14.3553 5 14.824 5 15.3553Z"
    fill="currentColor"
  />
);

export default ListCheck;
