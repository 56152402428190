import { addHook, sanitize as sanitiseWithDompurify } from "isomorphic-dompurify";

// only let target="_blank" exist on A tags and enforce rel="noopener"
addHook("afterSanitizeAttributes", (node: Element) => {
  if ("target" in node) {
    if (node.tagName === "A" && node.getAttribute("target") === "_blank") {
      node.setAttribute("rel", "noopener");
    } else {
      node.removeAttribute("target");
    }
  }
});

// ensure that the Trix metadata is also sanitized — not sure if we need this still but there's enough legacy content knocking about that I feel like we might as well keep it around.
addHook("afterSanitizeAttributes", (node: Element) => {
  if (node.hasAttribute("data-trix-attachment")) {
    try {
      const trixAttachmentData = JSON.parse(node.getAttribute("data-trix-attachment")!);
      if ("content" in trixAttachmentData) {
        trixAttachmentData.content = sanitiseWithDompurify(trixAttachmentData.content);
      }
      node.setAttribute("data-trix-attachment", JSON.stringify(trixAttachmentData));
      // eslint-disable-next-line no-empty
    } catch (e) {}
  }
});

const config = { ADD_TAGS: ["iframe"], ADD_ATTR: ["target"] };
/** Sanitizes the source code using dompurify, modified to allow iframes since that's what 90% of people will want. Also makes one other, more cursed modification. */
export default function sanitise(src: string) {
  // So it turns out that isomorphic-dompurify's sanitize command reverses the order of HTML attributes. This is not really a problem, per se, but it not being idempotent does mean that we can't use "sanitize(x) == x" to detect whether the code is valid. Luckily, reversing the order of the HTML attributes again fixes it, and we have a """convenient""" way to do that. So sanitize(sanitize(src)) it is.
  return sanitiseWithDompurify(sanitiseWithDompurify(src, config), config);
}
