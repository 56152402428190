import classNames from "classnames";
import React, {
  ChangeEvent,
  HTMLInputTypeAttribute,
  InvalidEvent,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";

import useGeneratedId from "../../../hooks/useGeneratedId";
import useRefOf from "../../../hooks/useRefOf";
import Button from "../Button";
import Icon, { IconSlug } from "../Icon";
import InputLabel from "../InputLabel";
import "./styles.scss";

export interface TextInputProps<T> {
  value: T;
  onChange: (value: T) => void;
  label: string;
  labelVisuallyHidden?: boolean;
  button?: TextInputButton;
  className?: string;
  placeholder?: string;
  size?: "small" | "medium";
  serverValidity?: string | null | false;
  hideValidation?: boolean;
  icon?: IconSlug;
  validateOnBlur?: boolean;
  required?: boolean;
  disabled?: boolean;
  maxLength?: number;
  pattern?: string;
  autoFocus?: boolean;
}

export interface TextInputType<T> {
  inputType: HTMLInputTypeAttribute;
  toString: (value: T) => string;
  parse: (input: string) => { invalid?: false; value: T } | { invalid: string; value?: null };
}

/** Represents a button that sits within a text input. You get ONE. This should be something that relates directly to this text box, like "search" (in a search box that submits like a form), "clear" (in a search box that updates live) or "show/hide password". It shouldn't be just a general button we want to put there for aesthetic reasons, eg, a "submit" button for a multi-input form just hanging out in one of the text inputs. */
export interface TextInputButton {
  label: string;
  icon: IconSlug;
  /** If this is omitted, the button submits the containing form instead of calling a callback */
  onClick: (() => void) | null;
  isActive?: boolean;
}

/**
 * In general you probably don't want to use this directly, use StringInput, LinkInput or NumericInput
 */
export default function TextInput<T>({
  value,
  type: { parse, toString, inputType },
  onChange,
  button,
  className,
  placeholder,
  label,
  labelVisuallyHidden,
  size,
  serverValidity,
  hideValidation,
  icon,
  validateOnBlur,
  disabled,
  ...props
}: TextInputProps<T> & {
  type: TextInputType<T>;
} & Pick<
    React.DetailedHTMLProps<React.InputHTMLAttributes<HTMLInputElement>, HTMLInputElement>,
    "required" | "min" | "max"
  >) {
  const id = useGeneratedId();
  const root = useRef<HTMLDivElement>(null);
  const input = useRef<HTMLInputElement>(null);
  const [textValue, setTextValue] = useState(toString(value));

  // Stores the message from the in-built browser validation, so it's available in a Reacty context. It only updates when validation is triggered so it might not always perfectly match the inputs validity field.
  const [htmlValidationMessage, setHtmlValidationMessage] = useState("");

  const onChangeEvent = useCallback((e: ChangeEvent<HTMLInputElement>) => setTextValue(e.target.value), []);

  // Turn the input string into a typed value
  const parsed = useMemo(() => parse(textValue), [parse, textValue]);
  const parsedRef = useRefOf(parsed);

  // Update the display when the prop changes
  useEffect(() => {
    if (parsedRef.current.value !== value) setTextValue(toString(value));
  }, [parsedRef, toString, value]);

  // Update the value when the text changes
  const valueRef = useRefOf(value);
  useEffect(() => {
    if (parsed.invalid !== false) {
      input.current?.setCustomValidity(parsed.invalid || "Please enter a valid value");
      return;
    }
    input.current?.setCustomValidity("");
    setHtmlValidationMessage("");
    if (parsed.value !== valueRef.current) onChange(parsed.value);
  }, [onChange, parsed, valueRef]);

  const onClickRoot = useCallback(() => root.current?.focus(), [root]);

  const onBlur = useCallback(() => {
    if (validateOnBlur) {
      // If parsed.invalid is set here, then that should have been passed through to the input's customValidity field, which will be reported here. If the input is invalid for some other reason, that will also be reported, but we can still run the rest of this callback so don't bail out.
      input.current?.checkValidity();
    }
    if (parsed.invalid !== false) return;
    // Normalise the text value — eg, remove leading zeros from a numeric input
    setTextValue(toString(parsed.value));
  }, [parsed, toString, validateOnBlur]);

  const onInvalid = useCallback(
    (e: InvalidEvent<HTMLInputElement>) => setHtmlValidationMessage(e.target.validationMessage),
    [],
  );

  const validationMessageToShow = useMemo(() => {
    // If this just isn't a meaningful value at all, that's the most important error, so show that:
    if (parsed.invalid !== false) {
      return parsed.invalid || "Please enter a valid value";
    }
    // If it's a valid message but (eg) blank in a required field, show that:
    if (htmlValidationMessage) return htmlValidationMessage;
    // If the current value is (as far as we can tell) valid, but the server rejected the last submitted value, show that:
    if (serverValidity) return serverValidity;
    // Otherwise we seem to be valid.
    return null;
  }, [htmlValidationMessage, parsed, serverValidity]);

  const rootElClassName = classNames("c-text-input", className, {
    "is-medium": size === "medium",
    "is-invalid": !!validationMessageToShow,
    "has-prefix": !!icon,
    "is-disabled": disabled,
  });

  return (
    <div className={rootElClassName} ref={root} onClick={onClickRoot}>
      <InputLabel htmlFor={id} visuallyHidden={labelVisuallyHidden}>
        {label}
      </InputLabel>
      <div className="c-text-input__input-wrapper">
        {icon && (
          <span className="c-text-input__icon">
            <Icon icon={icon} />
          </span>
        )}
        <input
          ref={input}
          id={id}
          className="c-text-input__input"
          value={textValue}
          onChange={onChangeEvent}
          onBlur={onBlur}
          disabled={disabled}
          onInvalid={onInvalid}
          placeholder={placeholder}
          autoComplete="none"
          type={inputType}
          {...props}
        />
        <div className="c-text-input__suffix" onClick={swallowEvent}>
          {validationMessageToShow ? <span className="c-text-input__invalid-icon">!</span> : null}
          {button ? (
            <Button
              iconButton
              icon={button.icon}
              variant="tertiary"
              submit={!button.onClick}
              onClick={button.onClick ?? undefined}
              label={button.label}
              isActive={button.isActive}
            />
          ) : null}
        </div>
      </div>
      {validationMessageToShow && !hideValidation ? (
        <p className="c-text-input__validation c-text-input__validation--custom">{validationMessageToShow}</p>
      ) : null}
    </div>
  );
}

/** Useful for when you end up putting a button "inside" another control — if someone clicks the button, you don't want the click event to propagate up and focus the input the button is in. Ofc really the button is just _near_ the text box, but we do such a good job of pretending it's inside it that we need to fix this case */
function swallowEvent(e: React.MouseEvent<HTMLDivElement>) {
  e.stopPropagation();
}
