import React from "react";

const Phone = () => (
  <path
    d="M13.7188 10.625L17.2188 12.125C17.7812 12.3438 18.0938 12.9375 17.9688 13.5313L17.2188 17.0313C17.0938 17.5938 16.5625 18.0313 16 18.0313C15.7812 18.0313 15.5938 18 15.4062 18C15.0938 18 14.7812 17.9688 14.5 17.9375C7.46875 17.1875 2 11.25 2 4.00002C2 3.43752 2.40625 2.90627 2.96875 2.78127L6.46875 2.03127C7.0625 1.90627 7.65625 2.21877 7.875 2.78127L9.375 6.28127C9.59375 6.78127 9.46875 7.37502 9.03125 7.71877L7.75 8.78127C8.59375 10.2188 9.78125 11.4063 11.2188 12.25L12.2812 10.9688C12.625 10.5313 13.2188 10.4063 13.7188 10.625ZM15.7812 16.5L16.4375 13.4063L13.3125 12.0625L12.4062 13.1875C11.9375 13.75 11.125 13.9063 10.4688 13.5313C8.8125 12.5625 7.4375 11.1875 6.46875 9.53127C6.09375 8.87502 6.25 8.06252 6.8125 7.59377L7.9375 6.68752L6.59375 3.56252L3.5 4.21877C3.59375 10.9688 9.03125 16.4063 15.7812 16.5Z"
    fill="currentColor"
  />
);

export default Phone;
