import React from "react";

const ArrowPointer = () => (
  <path
    d="M10 8.50008C10.8125 8.50008 11.5 9.18758 11.5 10.0001V12.9688L14.5625 5.43758L7.03125 8.50008H10ZM4.5 10.0001C4 10.0001 3.59375 9.68758 3.5 9.21883C3.40625 8.75008 3.65625 8.28133 4.09375 8.09383L15.0938 3.59383C15.4688 3.43758 15.9062 3.53133 16.1875 3.81258C16.4688 4.09383 16.5625 4.53133 16.4062 4.90633L11.9062 15.9063C11.7188 16.3438 11.25 16.5938 10.7812 16.5001C10.3125 16.4063 10 16.0001 10 15.5001V10.0001H4.5Z"
    fill="currentColor"
  />
);

export default ArrowPointer;
