import React from "react";

const AlignSlash = () => (
  <path
    d="M1.1875 2.1875L3.09375 3.65625C3.21875 3.40625 3.46875 3.25 3.75 3.25H16.25C16.6562 3.25 17 3.59375 17 4C17 4.4375 16.6562 4.75 16.25 4.75H4.5L7.6875 7.25H16.25C16.6562 7.25 17 7.59375 17 8C17 8.4375 16.6562 8.75 16.25 8.75H9.59375L12.7812 11.25H16.25C16.6562 11.25 17 11.5937 17 12C17 12.4375 16.6562 12.75 16.25 12.75H14.7187L19.6875 16.6875C20.0312 16.9375 20.0937 17.4062 19.8125 17.7187C19.5625 18.0625 19.0937 18.125 18.7812 17.8437L0.281248 3.34375C-0.0625017 3.09375 -0.125002 2.625 0.156248 2.3125C0.406248 1.96875 0.874998 1.90625 1.1875 2.1875ZM14.9062 16.75H3.75C3.3125 16.75 3 16.4375 3 16C3 15.5937 3.3125 15.25 3.75 15.25H13L14.9062 16.75ZM9.8125 12.75H3.75C3.3125 12.75 3 12.4375 3 12C3 11.5937 3.3125 11.25 3.75 11.25H7.90625L9.8125 12.75ZM4.75 8.75H3.75C3.3125 8.75 3 8.4375 3 8C3 7.8125 3.0625 7.65625 3.15625 7.53125L4.75 8.75Z"
    fill="currentColor"
  />
);

export default AlignSlash;
