import React from "react";
import { renderToString } from "react-dom/server";

import { validatedHtmlOrLexical } from "../../shared/block-editor-data/html-or-lexical";
import BlockEditorContent from "../../shared/block-editor-data/react";
import { Theme } from "../../shared/block-editor-data/theme";
import { validateRichTextDocumentOrThrow } from "../../shared/block-editor-data/validate";

export const THEME: Theme = {
  styles: {
    ChartContainer: "insight_body__chart",
  },
  stringFallback: true,
};

/** Returns the HTML as a string, if it is HTML and it passes validation, otherwise renders the data to an HTML string as Lexical (again if it passes validation) */
export function renderedHtmlOrLexical(document: string | null | undefined) {
  if (!document) return "";
  const validated = validatedHtmlOrLexical(document);
  if (typeof validated === "string") return validated;
  return renderToString(<BlockEditorContent content={validated} theme={THEME} />);
}

/** Returns a Lexical document as a string, if it passes validation. Otherwise throws an exception. */
export function renderedLexical(doc: string | null | undefined) {
  if (!doc) return "";
  const document = validateRichTextDocumentOrThrow(doc);
  return renderToString(<BlockEditorContent content={document} theme={THEME} />);
}
